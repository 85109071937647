@tailwind base;
@tailwind components;
@tailwind utilities;



/* SCROLLBAR */
/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #666666;
    cursor: grab;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #494949;
    cursor: grab;
}